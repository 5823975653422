import React from "react"
import Layout from "../components/layout"
import { Container, Box } from "@material-ui/core"
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../theme/blog-theme'
import Hero from '../components/hero/hero'
import Wave from '../components/wave/wave'
import '../theme/about.css'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'


const AboutPage = () => {

  const headerStyles = {
    color: theme.palette.primary.main,
    fontSize: '2.2rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    position: 'relative',
    display: 'inline-block'
  }

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <Hero height={'350px'} position={'relative'}>
          <Container maxWidth="lg" >
            <Box pt={8}>
              <h1
                className="about-header"
                style={{
                  fontSize: '3rem',
                  color: '#ffffff',
                  position: 'relative',
                  display: 'inline-block',
                  paddingTop: '2rem'
                }}>About</h1>
              <p
                style={{
                  color: '#ffffff',
                  fontSize: '1.3rem',
                  maxWidth: '30rem',
                  lineHeight: '1.8'
                }}>Learn everything you can about the world of tech from Tech How To!</p>
            </Box>
          </Container>
        </Hero>
        <Wave    
          style={{
            width: '100vw',
            transform: 'translateY(-40%)',
            marginBottom: '0',
            position: 'absolute'
          }} />

        <Container maxWidth="lg" >
            <Box pt={10} pb={5}>
              <h2
                style={headerStyles}>
                 Purpose 
              </h2>

              <p
                style={{
                  lineHeight: '2',
                  fontSize: '20px',
                  position: 'relative'
                }}>
                Tech How To is here to help everyone learn. Whether that comes in the form of coding samples, resource links or just blocks of text, THT 
                wants to help you learn and grow as a person and a developer. The internet has given the author a lot of help
                throughout the years, and Tech How To is here to be that guiding voice to any young developer in need that he had when he
                knew relatively little.
              </p>

              <div style={{margin: '5rem 0'}}>
                <h2
                  style={headerStyles}
                >
                  Topics
                </h2>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3 style={{color: theme.palette.primary.main}}>Javascript</h3>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <p style={{color: theme.palette.secondary.main}}>
                      Tech How to will be taking a deep dive into Javascript Concepts that every developer should know 
                      as well as topics that may get a bit more advanced. This includes but is not limited to frontend
                      and backend frameworks and coding samples.
                    </p>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1b-content"
                    id="panel1b-header"
                  >
                    <h3 style={{color: theme.palette.primary.main}}>Developer Life</h3>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <p style={{color: theme.palette.secondary.main}}>
                      General tips about developer life that might allow
                      you to become a more effective developer, faster. Things we all wish we knew before we started out.
                    </p>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                  >
                    <h3 style={{color: theme.palette.primary.main}}>Personal Work</h3> 
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <p style={{color: theme.palette.secondary.main}}>
                      The author behind the site (Justin Hurley) works on projects of varying natures and 
                      loves to show off his progress as much as possible.
                    </p>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>

            </Box>
        </Container>

      </ThemeProvider>
    </Layout>
  )
}

export default AboutPage