import React from "react"
const Hero = (props) => {

  const { children } = props
  
  return (
   <div
    className="hero-background"
    style={{
      background: `linear-gradient(90deg, rgba(7,148,101,1) 0%, rgba(26,166,119,1) 86%, rgba(6,147,99,1) 98%)`,
      minHeight: props.height,
      position: props.position,
      width: '100%'
    }}> 
      {children}
   </div>
  )
}

Hero.defaultProps = {
  height: '450px'
}
export default Hero